
// timer
import { getTransaction, TransactionStatus } from './transactions'
import { ITransactionStorage, listTransactionKeys, listStoredTransactionByKey, updateLocalTransaction } from './transactionstorage'

// const CALLBACK_URL = "https://gxliao.life/api/test/notify"
const CALLBACK_URL = "https://chain.nanjingyunpeng.cn/api/notify/index"

export class TransactionTimer {

    public start() {
        console.log("Ready to schedule.")
        this.processLoop()
    }

    public processLoop() {
        setTimeout(() => {
            this.processTransactionResults()
            this.processLoop()
        }, 10000) // 10 sec
    }

    public async processTransactionResults() {
        console.log("schedule .......")

        try {
            const keys = listTransactionKeys()
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i]
                const currentStoredList: ITransactionStorage[] = listStoredTransactionByKey(key)
                for (i = 0; i < currentStoredList.length; i++) {
                    await this.processTransactionResult(currentStoredList[i])
                }
                updateLocalTransaction(key, currentStoredList)
            }
        } catch (error) {
            console.error('Schedule local transaction error: ', error)
        }
    }

    public async processTransactionResult(transactionStorage: ITransactionStorage) {
        const transaction = transactionStorage.transactionResult
        const status = transactionStorage.transactionResult.status

        if (this.isFinished(status) && transactionStorage.sendTimes > 0) { // already finished
            return
        }

        if (!transaction.txhash) { // txhash is null if this transaction is rejected.
            return 
        }

        const newer = await getTransaction(transaction.txhash, transaction.chainId)
        if (newer.status && status !== newer.status) { // status is changed
            transaction.status = newer.status
            transaction.gasUsed = newer.gasUsed
        }

        if (this.isFinished(transaction.status)) {
            // send to remote
            const sended = await this.notifyResult(transactionStorage)
            if (sended) {
                transactionStorage.sendTimes += 1
            }
        }
    }

    private isFinished(status: TransactionStatus | undefined): boolean {
        return status === TransactionStatus.CONFIRMED || status === TransactionStatus.REJECTED || status === TransactionStatus.FAILED
    }

    private notifyResult = async (result: ITransactionStorage): Promise<boolean> => {
        try {
            const url = result.others ? CALLBACK_URL + "?" + result.others : CALLBACK_URL
            console.log("Transaction timer CALLBACK_URL: ", url)

            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(result.transactionResult),
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            });

            console.log('notify response:', response);

            return response.ok
        } catch (error) {
            console.error("notify transaction result error:", error);
            return false;
        }
    }
}