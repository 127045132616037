
// 支付界面
import * as React from "react";
import styled from "styled-components";
import 'antd/dist/antd.css';
import Column from "../components/Column";
import WalletConnectDecorator from "../helpers/wallets";
import { supportedMerchantAccount } from "src/helpers/payments";
import { Button, Descriptions, Tag, Spin, Alert, Skeleton, Dropdown, MenuProps, Menu } from "antd";
import { ReloadOutlined, LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { ellipseAddress, ellipseText, formatTokenUnits, getChainData, isChainSupported, symbolValue } from "../helpers/utilities";
import { convertHexToNumber, convertHexToString, convertStringToNumber } from "../helpers/bignumber";
import { ethers, BigNumber } from "ethers";
import { storeLocalTransaction } from "src/helpers/transactionstorage";
import { TransactionTimer } from "src/helpers/transactiontimer";
import { ITransactionResult } from "src/helpers/transactions";
import PaymentResultPage from "./PaymentResultPage";
import Card from "src/components/Card";
import Fonter from "src/components/Footer";
import TagButton from "src/components/TagButton";

const WALLET_CONNECT = 'walletconnect'
const BINANCE_PAY = 'binancepay'

const transactionTimer = new TransactionTimer()

const SLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  text-align: center;
`;

interface IRuntimeMsg {
  type?: "ERROR",
  msg?: string,
  description?: string
}

interface IPaymentContext {
  syncBalanceing: boolean
  wcDecorator: WalletConnectDecorator
  pendingRequest: boolean
  pendingPayment: boolean
  tokenSymbol: string | undefined // 代币符号，USDT, USDC等
  tokenValue: number | undefined // 代币数量
  tokenBalanceOnChain: number | undefined // 链上的代币余额
  currencyBalanceOnChain: number | undefined
  gasLimit: number | undefined
  gasPrice: number | undefined
  estimatedGas: string | undefined
  tokenBalanceFetching: boolean,
  currencyBalanceFetching: boolean,
  gasFetching: boolean,
  fetched: boolean, // 基础信息是否已经获取结束
  runtimeMsg: IRuntimeMsg // 运行时错误,
  transactionResult: ITransactionResult | undefined
}

const INITIAL_STATE: IPaymentContext = {
  syncBalanceing: false,
  wcDecorator: new WalletConnectDecorator(),
  pendingRequest: false,
  pendingPayment: false,
  tokenSymbol: undefined,
  tokenValue: undefined,
  tokenBalanceOnChain: undefined,
  currencyBalanceOnChain: undefined,
  gasLimit: undefined,
  gasPrice: undefined,
  estimatedGas: undefined,
  tokenBalanceFetching: false,
  currencyBalanceFetching: false,
  gasFetching: false,
  fetched: false,
  runtimeMsg: {},
  transactionResult: undefined
}

function SwitchChainPopup(props: any): JSX.Element {
  const [open, setOpen] = React.useState(false)

  const handleMenuClick: MenuProps['onClick'] = e => {
    let chainId: string | number = e.key
    if (typeof e.key === 'string') {
      chainId = convertStringToNumber(e.key)
    }
    props.onClick(chainId)
    setOpen(false)
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={supportedMerchantAccount().map((it, index) => {
        return {
          label: it.chainDetail!!.name,
          key: it.chainId
        }
      })}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 2px 8px 0px rgba(2, 26, 63, 0.04)",
        fontFamily: "PingFangSC-Regular, sans-serif"
      }}
    />
  );

  return <Dropdown overlay={menu} onOpenChange={handleOpenChange} open={open} trigger={['click']}>
    <TagButton>Switch</TagButton>
  </Dropdown>
}

class PaymentPage extends React.Component<any, any> {

  public state: IPaymentContext = {
    ...INITIAL_STATE
  }

  constructor(props: any) {
    super(props)
    transactionTimer.start()
  }

  public componentDidMount(): void {
    const params = this.getQueryParams()

    const {
      wcDecorator
    } = this.state

    if (params.get("tokenSymbol")) {
      this.setState({ tokenSymbol: params.get("tokenSymbol") })
    }
    if (params.get("tokenValue")) {
      this.setState({ tokenValue: params.get("tokenValue") })
    }

    wcDecorator.addEventListener("connected", () => {
      this.syncBalance()
    })

    wcDecorator.addEventListener("disconnected", () => {
      this.clearState()
    })

    wcDecorator.addEventListener("chainChanged", (chainId: number) => {
      this.syncBalance()
    })

    wcDecorator.addEventListener("accountChanged", (account: string) => {
      this.syncBalance()
    })

    this.connectIfNotConnected()
  }

  public authConnect = async () => {
    const localStorageInfo = localStorage.getItem("walletconnect")
    if ((!localStorageInfo) || typeof localStorageInfo === 'undefined') {
      return
    }

    const chainId = JSON.parse(localStorageInfo).chainId
    if (chainId === 56) {
      await this.connect(BINANCE_PAY)
    } else {
      await this.connect(WALLET_CONNECT)
    }
  }

  public connectIfNotConnected = async () => {
    this.connect(WALLET_CONNECT)
  }

  public connect = async (channelId: string) => {
    this.clearRuntimeMsg()

    const {
      wcDecorator
    } = this.state

    try {
      switch (channelId) {
        case WALLET_CONNECT:
          wcDecorator.connect()
          this.syncBalance()
          break;
        case BINANCE_PAY:
          wcDecorator.connect()
          this.syncBalance()
          return;
      }
    } catch (error) {
      this.errorRuntimeMsg("Connect error", error.message)
      console.error(error)
    }
  }

  public disconnect = async (channelId: string) => {
    this.clearRuntimeMsg()

    const {
      wcDecorator
    } = this.state

    try {
      switch (channelId) {
        case WALLET_CONNECT:
          await wcDecorator.disconnect()
          this.syncBalance()
          break;
        case BINANCE_PAY:
          await wcDecorator.disconnect()
          this.syncBalance()
          return;
      }
    } catch (error) {
      this.errorRuntimeMsg("Disconnect error", error.message)
      console.error(error)
    }
  }

  public switchChain = async (chainId: number) => {
    this.clearRuntimeMsg()

    const {
      wcDecorator
    } = this.state

    try {
      await wcDecorator.switchChain(chainId)
    } catch (error) {
      this.errorRuntimeMsg("Switch chain error", error.message)
      console.error(error)
    }
  }

  public sendTransaction = async () => {
    this.clearRuntimeMsg()

    const {
      tokenSymbol,
      tokenValue,
      wcDecorator,
      gasLimit,
      gasPrice
    } = this.state

    const {
      chainId,
      address
    } = wcDecorator;

    if (!tokenSymbol || tokenSymbol === "" || !tokenValue || tokenValue === 0) {
      throw new Error("币种或金额未指定")
    }

    try {
      // 根据币种和当前的链确定收件地址
      const to = this.getMerchantAddress(chainId!!)

      // await wcDecorator.sendTransaction(to, tokenValue) // send native currency
      const transaction = await wcDecorator.sendToken(to, tokenValue, tokenSymbol, { gasLimit, gasPrice })

      storeLocalTransaction(address.toLowerCase(), transaction, this.getQueryParamsString())
      this.setState({ transactionResult: transaction })
    } catch (error) {
      this.errorRuntimeMsg("Send token error", error.message)
      console.error(error)
    } finally {
      this.setState({ pendingPayment: false })
    }
  }

  public syncBalance = () => {
    const {
      tokenSymbol,
      tokenValue,
      wcDecorator
    } = this.state
    const {
      connected,
      chainId
    } = wcDecorator

    if (!tokenSymbol || !tokenValue) {
      return
    }

    if (!connected) {
      this.setState({
        runtimeMsg: {},
        fetched: false
      })
      return
    }
    this.setState({
      fetched: false
    })

    this.setState({ tokenBalanceFetching: true })
    wcDecorator.getBalanceOfERC20(tokenSymbol).then((balanceHex) => {
      const balance = convertHexToNumber(balanceHex)
      this.setState({
        tokenBalanceOnChain: formatTokenUnits(balance, tokenSymbol, chainId!!)
      })
    }).catch(() => {
      this.setState({
        tokenBalanceOnChain: undefined
      })
    }).finally(() => {
      this.setState({ tokenBalanceFetching: false })
      this.checkAndSetFetched()
    })

    this.setState({ currencyBalanceFetching: true })
    wcDecorator.getBalance().then((balanceHex) => {
      this.setState({
        currencyBalanceOnChain: convertStringToNumber(ethers.utils.formatEther(balanceHex))
      })
    }).catch(() => {
      this.setState({
        currencyBalanceOnChain: undefined
      })
    }).finally(() => {
      this.setState({ currencyBalanceFetching: false })
      this.checkAndSetFetched()
    })

    this.setState({ gasFetching: true })
    const to = this.getMerchantAddress(chainId!!)
    wcDecorator.estimateGas(to, tokenValue, tokenSymbol).then((gas) => {
      const estimatedGas = BigNumber.from((gas.gasPrice || 0) * (gas.gasLimit || 0))
      this.setState({
        gasPrice: gas.gasPrice,
        gasLimit: gas.gasLimit,
        estimatedGas: ethers.utils.formatUnits(estimatedGas, 18)
      })
    }).catch(() => {
      this.setState({
        gasPrice: undefined,
        gasLimit: undefined,
        estimatedGas: undefined
      })
    }).finally(() => {
      this.setState({ gasFetching: false })
      this.checkAndSetFetched()
    })
  }

  public checkAndSetFetched = () => {
    const {
      tokenBalanceFetching,
      currencyBalanceFetching,
      gasFetching
    } = this.state
    if (!tokenBalanceFetching && !currencyBalanceFetching && !gasFetching) {
      this.setState({ fetched: true })
    }
  }

  public render = () => {
    const {
      wcDecorator,
      tokenSymbol,
      tokenValue,
      tokenBalanceOnChain,
      currencyBalanceOnChain,
      estimatedGas,
      tokenBalanceFetching,
      currencyBalanceFetching,
      gasFetching,
      fetched,
      syncBalanceing,
      transactionResult
    } = this.state
    const {
      address,
      connected,
      chainId
    } = wcDecorator

    let activeChain
    try {
      activeChain = chainId ? getChainData(chainId) : undefined
    } catch (error) {
      console.error("Get chain data error: ", error)
    }

    const payableMsg = () => {
      if (!tokenSymbol) {
        return "Token symbol is not appointed"
      }
      if (!tokenValue || tokenValue === 0) {
        return "Token value is not appointed"
      }
      if (!connected) {
        return "Wallet is not connected"
      }
      if (!isChainSupported(chainId!!)) {
        return "Current chain is not supported"
      }
      if (tokenBalanceOnChain !== undefined && tokenValue !== undefined && tokenValue > tokenBalanceOnChain) {
        return "Token insufficient"
      }
      if (currencyBalanceOnChain === 0) {
        return "Currency insufficient"
      }
      return undefined
    }

    const syncBalanceWithStatus = async () => {
      setTimeout(() => {
        this.setState({
          syncBalanceing: true,
          tokenBalanceOnChain: undefined,
          currencyBalanceOnChain: undefined,
          gasPrice: undefined,
          gasLimit: undefined,
          estimatedGas: ""
        })
      })

      try {
        this.syncBalance()
      } finally {
        setTimeout(() => {
          this.setState({ syncBalanceing: false })
        }, 500)
      }
    }

    return (
      <SLayout>
        <Column maxWidth={500} spanHeight>
          {/* <div>{window.location.toString()}</div> */}
          {/* <Button onClick={() => navigate("/abc")}>TEST</Button> */}
          {!transactionResult && <>
            <Card title="Connect Status">
              <Descriptions size="small" column={1}>
                <Descriptions.Item label="Wallet Account">
                  {ellipseAddress(address || "", 5)}&nbsp;&nbsp;
                  {!connected
                    ? <TagButton onClick={() => this.connect('walletconnect')}>Connect</TagButton>
                    : <TagButton onClick={() => this.disconnect('walletconnect')}>Disconnect</TagButton>
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Wallet Chain">
                  {activeChain ? activeChain.name : symbolValue(chainId)}
                  {connected && !isChainSupported(chainId!!)
                    ? <Tag icon={<ExclamationCircleOutlined />} color="warning" style={{ background: "transparent", border: "none" }} />
                    : <>&nbsp;&nbsp;</>
                  }
                  {connected && <SwitchChainPopup onClick={(chainId: number) => this.switchChain(chainId)} />}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card title="Chain Info" titleDetail={connected &&
              <>{syncBalanceing
                ? <Button type="link" size="small" icon={<LoadingOutlined style={{ color: "#FF7143" }} />} style={{ verticalAlign: "0px" }} />
                : <Button type="link" size="small" icon={<ReloadOutlined style={{ color: "#FF7143" }} />} onClick={syncBalanceWithStatus} style={{ verticalAlign: "0px" }} />
              }</>
            }>
              <Descriptions size="small" column={1}>
                <Descriptions.Item label={"Balance(" + (activeChain ? activeChain.native_currency.symbol : "UNKNOWN") + ")"}>
                  {!currencyBalanceFetching
                    ? symbolValue(currencyBalanceOnChain) || "..."
                    : <Skeleton.Button active={true} size="small" shape="round" />}&nbsp;
                  {activeChain ? activeChain.native_currency.symbol : "UNKNOWN"}
                </Descriptions.Item>
                <Descriptions.Item label={"Balance(" + tokenSymbol + ")"}>
                  {!tokenBalanceFetching
                    ? symbolValue(tokenBalanceOnChain) || "..."
                    : <Skeleton.Button active={true} size="small" shape="round" />}&nbsp;
                  {tokenSymbol}
                </Descriptions.Item>
              </Descriptions>
            </Card>

            <Card title="Payment Info">
              <Descriptions size="small" column={1}>
                <Descriptions.Item label="Estimated Gas">
                  {!gasFetching
                    ? symbolValue(estimatedGas)
                    : <Skeleton.Button active={true} size="small" shape="round" />}
                  &nbsp;
                  {activeChain ? activeChain.native_currency.symbol : "UNKNOWN"}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Amount">{tokenValue}&nbsp;{tokenSymbol}</Descriptions.Item>
              </Descriptions>
            </Card>

            {payableMsg() !== undefined &&
              <Alert message={payableMsg()} banner style={{
                marginTop: "16px",
                borderRadius: "100px"
              }} />}


            {this.state.runtimeMsg.type &&
              <Alert
                message={this.state.runtimeMsg.msg}
                description={this.state.runtimeMsg.description}
                type="error"
                style={{
                  width: "95%",
                  marginTop: "16px",
                  marginBottom: "0px",
                  // borderRadius: "100px"
                }}
              />
            }

            <Fonter center>
              <Spin spinning={!fetched} delay={100}>
                <Button
                  type="primary"
                  size="large"
                  onClick={this.sendTransaction}
                  shape="round"
                  style={{
                    margin: "32px",
                    width: "80%",
                    minWidth: "300px",
                    border: "0px",
                    backgroundColor: (payableMsg() !== undefined) ? "#DDDDDD" : "#FF7143"
                  }}
                  disabled={payableMsg() !== undefined}
                >
                  {"Confirm Payment"}
                </Button>
              </Spin>
            </Fonter>
          </>
          }
          {transactionResult && <PaymentResultPage transactionResult={transactionResult} />}
        </Column>
      </SLayout>
    )
  }


  private clearRuntimeMsg = () => {
    setTimeout(() => {
      this.setState({ runtimeMsg: {} })
    }, 200)
  }

  private clearState = () => {
    setTimeout(() => {
      this.setState({
        runtimeMsg: {},
        tokenBalanceOnChain: undefined,
        currencyBalanceOnChain: undefined,
        gasPrice: undefined,
        gasLimit: undefined,
        estimatedGas: ""
      })
    }, 200)
  }

  private errorRuntimeMsg = (_msg: string, _description: string) => {
    setTimeout(() => {
      this.setState({
        runtimeMsg: {
          type: "ERROR",
          msg: _msg,
          description: _description
        }
      })
    }, 200)
  }

  private getMerchantAddress(chainId: number): string {
    const accounts = supportedMerchantAccount().filter((account) => account.chainId === chainId)
    if (accounts.length === 0) {
      throw new Error(`chain_id = ${chainId} has no merchant address.`)
    }
    return accounts[0].address
  }

  private getQueryParams(): Map<any, any> {
    const map = new Map()

    const query = this.getQueryParamsString()
    const vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=")
      map.set(pair[0], pair[1])
    }

    return map
  }

  private getQueryParamsString(): string {
    return window.location.search.substring(1)
  }
}

export default PaymentPage;