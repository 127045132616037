import { ethers, BigNumber } from "ethers";

// chainId -> InfuraProvider
const map = new Map<number, ethers.providers.InfuraProvider>()

export enum TransactionStatus {
    SUBMITTED = 'SUBMITTED', // TODO
    FAILED = 'FAILED',
    REJECTED = 'REJECTED',
    CONFIRMED = 'CONFIRMED',
}

export interface ITransactionResult {
    chainId?: number,
    txhash?: string,
    from?: string,
    to?: string | undefined,
    status?: TransactionStatus | undefined,
    contractAddress?: string | undefined,
    tokenSymbol?: string, // USDT, USDT, BUSD, ...
    tokenValue?: number,
    gasUsed?: BigNumber | undefined
}

// get transaction by txhash
// @chainId `1` if not appointed
export const getTransaction = async (_txhash: string, _chainId?: number): Promise<ITransactionResult> => {
    if (!_chainId) {
        _chainId = 1 // default is eth mainnet which chain id is 1
    }

    let provider = map.get(_chainId)

    if (!provider) {
        provider = new ethers.providers.InfuraProvider(_chainId)
        map.set(_chainId, provider)
    }
    
    const transactionReceipt = await provider.getTransactionReceipt(_txhash)
    const transaction = await provider.getTransaction(_txhash)

    console.log('transactionReceipt: ', transactionReceipt)
    console.log('transaction: ', transaction)

    if (transactionReceipt && transactionReceipt.status && transactionReceipt.status !== 1 && transactionReceipt.status !== 0) {
        console.error(`Unknown status for transaction ${_txhash} which status is ${transactionReceipt.status}`)
    }

    let _status: TransactionStatus | undefined
    if (transactionReceipt) {
        switch (transactionReceipt.status) {
            case 1:
                _status = TransactionStatus.CONFIRMED
                break
            case 0:
                _status = TransactionStatus.FAILED
                break
        }
    }

    return {
        chainId: _chainId,
        txhash: _txhash,
        from: transaction.from,
        to: transaction.to,
        status: _status,
        contractAddress: transactionReceipt && transactionReceipt.contractAddress,
        tokenValue: transaction.value.toNumber(),
        gasUsed: transactionReceipt && transactionReceipt.gasUsed
    }
}
