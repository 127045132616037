// 钱包连接器
import WalletConnect from "@walletconnect/client";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers, ethers, BigNumber } from "ethers";
import { IEstimatedGas, IAssetData } from "../helpers/types";
import { apiGetAccountAssets } from "../helpers/api";
import { convertNumberToString } from "../helpers/bignumber";
import { getChainData, parseTokenUnits, isChainSupported, checkChainSupported } from '../helpers/utilities'
import abi from '../abi/erc20-common-abi.json'
import { hexValue } from 'ethers/lib/utils';
import { ITransactionResult, TransactionStatus } from './transactions'

// const INFURA_ID = "bfc1123b1f44494a814561354a787fe2"
const INFURA_ID = "90c47cb88f5f4a1d9425509fc33badb8"
// const INFURA_ID = "e683cc5addbc488ab022e99f58a22a3c"

const EVENT_CONNECTED = "connected"
const EVENT_DISCONNECTED = "disconnected"
const EVENT_CHAIN_CHANGED = "chainChanged"
const EVENT_ACCOUNTS_CHANGED = "accountsChanged"
const EVENT_ERROR = "error"

class WalletConnectDecorator {

    public connector: WalletConnect | null = null
    public wcProvider: WalletConnectProvider | null = null
    public web3Provider: providers.Web3Provider | null = null
    public fetching: boolean = false
    public connected: boolean = false
    public connecting: boolean = false // 当前正在连接
    public chainId: number | undefined = undefined
    public pendingRequest: boolean = false
    public uri: string = ""
    public accounts: string[] | null = []
    public address: string = ""
    public assets: IAssetData[] | null = []

    // 监听器集合
    private listeners: Map<string, any[]> = new Map()

    public connect = () => {
        console.log("Wallet connecting...")

        if (this.connected) {
            console.log("Wallet already connected, skipping.")
            return
        }
        if (this.connecting) {
            console.log("Wallet is connecting, skipping.")
            return
        }

        // Create WalletConnect Provider
        const _wcProvider = new WalletConnectProvider({
            infuraId: INFURA_ID,
            // qrcode: false,
            rpc: {
                56: "https://bsc-dataseed.binance.org/",
            },
            qrcodeModalOptions: {
                desktopLinks: [
                    'ledger',
                    'tokenary',
                    'wallet',
                    'wallet 3',
                    'secuX',
                    'ambire',
                    'wallet3',
                    'apolloX',
                    'zerion',
                    'sequence',
                    'punkWallet',
                    'kryptoGO',
                    'nft',
                    'riceWallet',
                    'vision',
                    'keyring'
                ],
                mobileLinks: [
                    "metamask",
                    "imtoken",
                    "qubic",
                    "argent",
                    "trust",
                    "gnosis",
                    "kryptogo",
                    "tokenpocket"
                ]
            },
            clientMeta: { // TODO not work
                description: "Payment DAPP",
                url: "https://gxliao.life",
                icons: ["https://gxliao.life/logo.jpg"],
                name: "Payment-DAPP",
            }
        });

        //  Enable session (triggers QR Code modal)
        _wcProvider.enable().then(() => {
            this.connected = true

            // subscribe to events
            this.subscribeToEvents(_wcProvider)

            this.wcProvider = _wcProvider
            this.web3Provider = new providers.Web3Provider(_wcProvider, "any")

            this.chainId = _wcProvider.chainId
            this.accounts = _wcProvider.accounts
            this.address = this.accounts[0]

            console.log(`Wallet connected. Chain id=${this.chainId}. Address=${this.address}`)

            this.syncAccountAssets()
            
            this.triggerListener(EVENT_CONNECTED)
        }).catch((error) => {
            this.connected = false
            console.log("Wallet connect error: ", error)
        }).finally(() => {
            this.connecting = false
        })
    }

    // auto connect if this client is already connected.
    public async tryConnectIfConnected() {
        if (!localStorage.getItem("walletconnect")) {
            return
        }

        if (this.connected) {
            return
        }

        if (this.connecting) {
            return
        }

        this.connect()
    }

    // 切换链
    public switchChain = async (targetChainId: number) => {
        checkChainSupported(targetChainId)
        this.checkConnected()

        await this.tryConnectIfConnected()
        console.log("Switching chain to id=", targetChainId)

        if (targetChainId === this.chainId) {
            return
        }

        const hexChainId = hexValue(targetChainId)
        try {
            await this.wcProvider!!.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: hexChainId
                }]
            })

            console.log(`Switch chain to id=${targetChainId} succeed.`)
        } catch (error) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (error.message.includes("wallet_addEthereumChain")) {
                this.addChain(targetChainId)
                return
            }

            console.log(`Switch wallet chain from ${this.chainId} to ${targetChainId} error: `, error)
            throw error
        }
    }

    // TODO not finished.
    public addChain = async (targetChainId: number) => {
        checkChainSupported(targetChainId)
        this.checkConnected()

        const hexChainId = hexValue(targetChainId)

        // add chain using 'wallet_addEthereumChain'
        try {
            await this.wcProvider!!.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: hexChainId
                    // TODO more params
                }]
            });
        } catch (error) {
            // handle "add" error
            console.log(`Adding to wallet error: `, error)
            throw error
        }
    }

    // send native currency
    public sendTransaction = async (_to: string, _value: number) => {
        checkChainSupported(this.chainId)
        this.checkConnected()

        await this.tryConnectIfConnected()

        if (!this.web3Provider) {
            throw Error("Current session is not connected");
        }

        try {
            const signer = this.web3Provider.getSigner()
            const transValue = ethers.utils.parseEther(_value.toString())

            const result = await signer.sendTransaction({
                to: _to,
                value: transValue
            })

            console.log(result)
        } catch (error) {
            console.log("Send transaction error: ", error)
            throw error;
        }
    }

    public estimateGas = async (_to: string, _value: number, _tokenSymbol: string): Promise<IEstimatedGas> => {
        checkChainSupported(this.chainId)
        this.checkConnected()

        await this.tryConnectIfConnected()

        const erc20ABI = abi;

        if (!this.web3Provider) {
            throw Error("Current wallet is not connected");
        }

        const chain = getChainData(this.chainId!!)
        const tokens = chain.supported_tokens?.filter((token) => token.symbol === _tokenSymbol)

        if (!tokens || tokens.length === 0) {
            throw Error(`Token symbol=${_tokenSymbol} is not supported at chain id=${chain.chain_id}`);
        }
        const token = tokens[0]

        try {
            const signer = this.web3Provider.getSigner()

            const erc20Contract = new ethers.Contract(
                token.contractAddress!!,
                erc20ABI,
                signer
            );

            // gasPrice
            // const gasPrices = await apiGetGasPrices()
            // const _gasPrice = gasPrices.fast.price
            const _gasPrice = (await this.web3Provider.getGasPrice()).toNumber()
            // const gasPrice = sanitizeHex(convertStringToHex(convertAmountToRawNumber(_gasPrice, 9)))

            const parsedUnit = parseTokenUnits(_value, token.symbol, this.chainId!!)
            const _gasLimit = (await erc20Contract.estimateGas.transfer(_to, parsedUnit)).toNumber()

            return {
                gasPrice: _gasPrice,
                gasLimit: _gasLimit
            }
        } catch (error) {
            console.error("Estimate gas error: ", error)
            throw error
        }
    }

    public sendToken = async (_to: string, _value: number, _tokenSymbol: string,
        _estimatedGas?: IEstimatedGas): Promise<ITransactionResult> => {
        checkChainSupported(this.chainId)
        this.checkConnected()

        await this.tryConnectIfConnected()

        console.log(`Sending token. to: ${_to}, value: ${_value} ${_tokenSymbol}`)

        const erc20ABI = abi;

        if (!this.web3Provider) {
            throw Error("Current wallet is not connected");
        }

        const chain = getChainData(this.chainId!!)

        const tokens = chain.supported_tokens?.filter((token) => token.symbol === _tokenSymbol)

        if (!tokens || tokens.length === 0) {
            throw Error(`Token symbol=${_tokenSymbol} is not supported at chain id=${chain.chain_id}`);
        }

        const token = tokens[0]

        try {
            const signer = this.web3Provider.getSigner()

            const erc20Contract = new ethers.Contract(
                token.contractAddress!!,
                erc20ABI,
                signer
            );

            if (!_estimatedGas || !_estimatedGas.gasLimit || !_estimatedGas.gasPrice) {
                _estimatedGas = await this.estimateGas(_to, _value, _tokenSymbol)
            }
            console.log(`Using gas info: gasLimit=${_estimatedGas.gasLimit}, gasPrice=${_estimatedGas.gasPrice}`)

            const parsedUnit = parseTokenUnits(_value, token.symbol, this.chainId!!)

            const transaction: ITransactionResult = {
                chainId: this.chainId,
                from: this.address,
                to: _to,
                contractAddress: token.contractAddress,
                tokenSymbol: _tokenSymbol,
                tokenValue: _value
            }
            try {
                console.log(`Ready for tranfer.`)
                const transResult = await erc20Contract.transfer(_to, parsedUnit, {
                    // feeData: _feeData,
                    gasPrice: _estimatedGas.gasPrice, // TODO to optimise
                    gasLimit: _estimatedGas.gasLimit
                })

                console.log('Trans result: ', transResult)

                transaction.txhash = transResult.hash
                transaction.status = TransactionStatus.SUBMITTED
            } catch (error) {
                if (error.message.includes('User rejected')) {
                    console.log("Send token error: ", error.message)
                    transaction.status = TransactionStatus.REJECTED
                } else {
                    throw error
                }
            }

            return transaction
        } catch (error) {
            console.log("Send token error: ", error)
            throw error;
        }
    }

    public disconnect = async () => {
        console.log("Wallet disconnecting...")

        if (!this.connected) {
            this.resetContext()
            return
        }

        try {
            await this.wcProvider!!.disconnect()
        } catch (error) {
            console.log("Wallet disconnect error: ", error)
        }

        // try {
        //     await this.wcProvider.close()
        // } catch (error) {
        //     console.log("Wallet close error: ", error)
        // }

        try {
            this.resetContext()
        } catch (error) {
            console.log("Wallet reset context error: ", error)
        }

        localStorage.removeItem('walletconnect')
    }

    // get balance for current user
    public getBalance = async (): Promise<BigNumber> => {
        checkChainSupported(this.chainId)
        this.checkConnected()

        if (!this.web3Provider) {
            throw Error("Current wallet is not connected")
        }

        return this.web3Provider.getBalance(this.address)
    }

    // @tokenSymbol: USDT, USDC, BUSD
    public getBalanceOfERC20 = async (tokenSymbol: string): Promise<string> => {
        checkChainSupported(this.chainId)
        this.checkConnected()

        await this.tryConnectIfConnected()

        const erc20ABI = abi;

        if (!this.web3Provider) {
            throw Error("Current wallet is not connected");
        }

        const chain = getChainData(this.chainId!!)

        const tokens = chain.supported_tokens?.filter((token) => token.symbol === tokenSymbol)

        if (!tokens || tokens.length === 0) {
            throw Error(`Token symbol=${tokenSymbol} is not supported at chain id=${chain.chain_id}`);
        }

        const token = tokens[0]
        try {
            const signer = this.web3Provider.getSigner()

            const erc20Contract = new ethers.Contract(
                token.contractAddress!!,
                erc20ABI,
                signer
            );
            return erc20Contract.balanceOf(this.address)
        } catch (error) {
            console.log("Get balance from erc20 error: ", error.message)
            throw error
        }
    }

    public addEventListener(type: string, listener: any): void {
        const oneListeners = this.listeners.get(type) || []
        oneListeners.push(listener)
        this.listeners.set(type, oneListeners)
    }

    private triggerListener(type: string, ...params: any): void {
        const oneListeners = this.listeners.get(type) || []
        oneListeners.map((it) => {
            try {
                it(...params)
            } catch (error) {
                console.log("Invoke error: ", error)
            }
        })
    }

    private checkConnected() {
        if (!this.connected) {
            throw Error("Wallet is not connected.")
        }
        if (!this.web3Provider || !this.wcProvider) {
            throw Error("Wallet is connected, but the provider is not exists.")
        }
    }

    private subscribeToEvents = (wcProvider: WalletConnectProvider) => {
        wcProvider.on("accountsChanged", (accounts: string[]) => {
            if (accounts.toString() === this.accounts?.toString()) { // accounts not changed.
                return
            }
            console.log(`web3Provider.on("accountsChanged")`)

            this.accounts = accounts
            this.address = accounts[0]

            this.syncAccountAssets()

            this.triggerListener(EVENT_ACCOUNTS_CHANGED, this.address)
        });

        wcProvider.on("chainChanged", (chainId: number) => {
            if (chainId === this.chainId) { // chainId not changed.
                return
            }
            console.log(`web3Provider.on("chainChanged")`)

            this.chainId = chainId

            this.syncAccountAssets()
            this.triggerListener(EVENT_CHAIN_CHANGED, this.chainId)
        });

        wcProvider.on("connect", () => {
            console.log(`web3Provider.on("connect")`)
        });

        wcProvider.on("disconnect", (code: number, reason: string) => {
            console.log(`web3Provider.on("disconnect"), code=${code}, reason=${reason}`)

            this.resetContext();
            this.triggerListener(EVENT_DISCONNECTED)
        });

        wcProvider.on("error", (error: Error) => {
            console.log("walletconnect provider error: ", error)
            this.triggerListener(EVENT_ERROR, error)
        })
    }

    private syncAccountAssets = async () => {
        if (!this.address || this.chainId === 0) {
            return
        }

        this.fetching = true;
        try {
            // get account balances
            if (this.chainId === 56) {
                this.assets = null
            } else {
                this.assets = await apiGetAccountAssets(this.address, this.chainId!!);
            }
        } catch (error) {
            console.error("Get account assets error: ", error);
        } finally {
            this.fetching = false
        }
    }

    // 重置WalletConnectProvider上下文
    private resetContext = () => {
        this.connector = null;
        this.wcProvider = null;
        this.web3Provider = null;
        this.fetching = false;
        this.connected = false;
        this.chainId = undefined;
        this.pendingRequest = false;
        this.uri = "";
        this.accounts = [];
        this.address = "";
        this.assets = null;
    }
}

export default WalletConnectDecorator;
