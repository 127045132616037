import { IPaymentChannel } from "./types";
import { IMerchantAccount } from "../helpers/types";
import { CHAINS } from "./chains";

// 支持的支付渠道
export const SUPPORTTED_PAYMENT_CHANNEL: IPaymentChannel[] = [{
  id: "walletconnect",
  name: "WalletConnect",
  icon: ""
}, {
  id: "binancepay",
  name: "BinancePay",
  icon: ""
}]

// 系统支持的商家账号（一个链一个账号）
const SUPPORTTED_MERCHANT_ACCOUNT: IMerchantAccount[] = [
  {  // Ethereum Mainnet
    chainId: 1,
    address: "0xae9aeb25372ca8bcf949f3caa431b8b84e353658" // 确认账号地址在链上存在，否则容易永久损失金钱
  },
  { // Goerli Test Network   (only for test)
    chainId: 5,
    address: "0xae9aeb25372ca8bcf949f3caa431b8b84e353658" // 确认账号地址在链上存在，否则容易永久损失金钱
  },
  { // Binance Smart Chain Mainnet
    chainId: 56,
    address: "0xae9aeb25372ca8bcf949f3caa431b8b84e353658" // 确认账号地址在链上存在，否则容易永久损失金钱
  },
  // { // Conflux eSpace (Testnet)
  //   chainId: 71,
  //   address: "0x430a09141a2312b5D20555309bC7a20B5aCCc641" // 确认账号地址在链上存在，否则容易永久损失金钱
  // }
]

export function supportedMerchantAccount(): IMerchantAccount[] {
  return SUPPORTTED_MERCHANT_ACCOUNT.map((it) => {
    it.chainDetail = CHAINS.find((c) => c.chain_id === it.chainId)
    return it
  })
}
