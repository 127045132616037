import * as React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

import { Tag } from "antd";

interface ITagButtonStyleProps {
    onClick: React.MouseEventHandler<any> | undefined;
}

interface ITagButtonProps extends ITagButtonStyleProps {
    children: React.ReactNode;
}

const TagButton = (props: ITagButtonProps) => {
    const { onClick, children } = props;
    return (
        <Tag style={{
            cursor: "pointer",
            // textDecoration: "underline", 
            borderRadius: "12px",
            color: "#FF7143",
            // fontWeight: "bold",
            fontFamily: "PingFangSC-Medium, sans-serif",
            background: "#FFFFFF",
            borderColor: "#FF7143",
            borderWidth: "1px"
        }} onClick={onClick}>
            {children}
        </Tag>
    );
};

TagButton.propTypes = {
    children: PropTypes.node.isRequired,
};

TagButton.defaultProps = {
    center: false,
    onClick: undefined
};

export default TagButton;
