
// 支付结果界面
import * as React from "react";
import styled from "styled-components";
import 'antd/dist/antd.css';
import Column from "../components/Column";
import { Descriptions, Result, Tag } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import Icon from "../components/Icon";

import usdt from "../assets/usdt@2x.png";
import usdc from "../assets/usdc@2x.png";
import busd from "../assets/busd@2x.png";

import { ITransactionResult, TransactionStatus } from "src/helpers/transactions";
import { getStoredTransactionByAddressAndHash } from "src/helpers/transactionstorage";
import Card from "src/components/Card";
import HeaderCard from "src/components/HeaderCard";

const SLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  text-align: center;
`;

const INITIAL_STATE: ITransactionResult = {
  chainId: undefined,
  txhash: undefined,
  from: undefined,
  to: undefined,
  status: undefined,
  contractAddress: undefined,
  tokenValue: undefined,
  gasUsed: undefined
}

interface ICPaymentResultProps {
  transactionResult?: ITransactionResult;
}

class PaymentResultPage extends React.Component<ICPaymentResultProps, any> {

  public state: ITransactionResult = {
    ...INITIAL_STATE
  }

  constructor(props: ICPaymentResultProps) {
    super(props)
  }

  public componentDidMount() {
    if (!this.props.transactionResult) {
      return
    }

    this.setState({ from: this.props.transactionResult.from })
    this.setState({ txhash: this.props.transactionResult.txhash })
    this.setState({ tokenSymbol: this.props.transactionResult.tokenSymbol })
    this.setState({ tokenValue: this.props.transactionResult.tokenValue })
    this.setState({ status: this.props.transactionResult.status })

    setInterval( // TODO should use callback instead.
      () => {
        if (this.isFinished(this.state.status)) {
          return
        }
        if (!this.state.from || !this.state.txhash) {
          return
        }
        const newer = getStoredTransactionByAddressAndHash(this.state.from, this.state.txhash)

        if (this.state.status !== newer?.transactionResult.status) {
          this.setState({ status: newer?.transactionResult.status })
        }
      },
      1000
    )

  }

  public isFinished(status: TransactionStatus | undefined): boolean {
    return status === TransactionStatus.CONFIRMED || status === TransactionStatus.REJECTED || status === TransactionStatus.FAILED
  }

  public title = () => {
    const { tokenSymbol } = this.state
    let icon
    if (tokenSymbol === "USDT") {
      icon = usdt
    } else if (tokenSymbol === "USDC") {
      icon = usdc
    } else if (tokenSymbol === "BUSD") {
      icon = busd
    }
    return (
      <div>
        Sent&nbsp;
        {icon && <Icon src={icon} />}
        {(tokenSymbol || "")}
      </div>
    )
  }

  public statusStr = () => {
    const { status } = this.state

    if (!status) {
      return "info"
    }
    if (status === TransactionStatus.CONFIRMED) {
      return "success"
    }
    if (status === TransactionStatus.SUBMITTED) {
      return "info"
    }
    if (status === TransactionStatus.FAILED) {
      return "error"
    }
    if (status === TransactionStatus.REJECTED) {
      return "warning"
    }
    return "info"
  }

  public icon = () => {
    const { status } = this.state

    if (status === TransactionStatus.SUBMITTED) {
      return (
        <LoadingOutlined />
      )
    }
    return ""
  }

  public statusColor = () => {
    const { status } = this.state

    if (!status) {
      return "black"
    }
    if (status === TransactionStatus.CONFIRMED) {
      return "green"
    }
    if (status === TransactionStatus.SUBMITTED) {
      return "blue"
    }
    if (status === TransactionStatus.FAILED) {
      return "red"
    }
    if (status === TransactionStatus.REJECTED) {
      return "orange"
    }
    return "black"
  }

  public tokenFormat = () => {
    const { tokenValue, tokenSymbol } = this.state
    return (tokenValue || "...") + " " + (tokenSymbol || "")
  }

  public render() {
    const { status, txhash } = this.state

    return (
      <SLayout>
        <Column maxWidth={500} spanHeight>
          <HeaderCard>
            <Result
              status={this.statusStr()}
              icon={this.icon()}
              title={this.title()}
              subTitle="TIP: Please don't close is page before sent finished."
            />
          </HeaderCard>
          <Card title="Sent Detail">
            <Descriptions size="small" column={1}>
              <Descriptions.Item label={"Hash"} style={{ textAlign: "left" }}>
                {txhash || "..."}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag style={{ border: "0px", background: "#FFFFFF", color: this.statusColor(), fontWeight: "bold" }}>{status || "..."}</Tag>
              </Descriptions.Item>
              <Descriptions.Item label={"Value"}>
                {this.tokenFormat()}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Column>
      </SLayout>
    );
  }
}

export default PaymentResultPage;