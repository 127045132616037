import * as React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

interface ICardStyleProps {
    spanHeight: boolean;
    maxWidth: number;
    center: boolean;
    title?: string;
    titleDetail?: React.ReactNode
  }
  
  interface ICardProps extends ICardStyleProps {
    children: React.ReactNode;
  }
  
  const SCard = styled.div<ICardStyleProps>`
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 0px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 6px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(2, 26, 63, 0.04);
    font-family: PingFangSC-Regular, sans-serif;
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    justify-content: ${({ center }) => (center ? "center" : "flex-start")};

    // position: relative;
    // width: 100%;
    // margin: 0 auto;
    // display: flex;
    // flex-direction: Card;
    // align-items: center;
    // background: #F7F8FA;
  `;

  const SCardTitle = styled.div<any>`
    margin-bottom: 16px;
    font-size: medium;
    font: #303133;
    font-family: PingFangSC-Medium, sans-serif;
  `;
  
  const Card = (props: ICardProps) => {
    const { children, spanHeight, maxWidth, center, title, titleDetail } = props;
    return (
      <SCard {...props} spanHeight={spanHeight} maxWidth={maxWidth} center={center}>
        <SCardTitle>{title}<a style={{marginLeft: "10px"}}>{titleDetail}</a></SCardTitle>
        {children}
      </SCard>
    );
  };
  
  Card.propTypes = {
    children: PropTypes.node.isRequired,
    spanHeight: PropTypes.bool,
    maxWidth: PropTypes.number,
    center: PropTypes.bool,
  };
  
  Card.defaultProps = {
    spanHeight: false,
    maxWidth: 600,
    center: false,
  };
  
  export default Card;
  