import * as React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

interface IHeaderCardStyleProps {
  spanHeight: boolean;
}

interface IHeaderCardProps extends IHeaderCardStyleProps {
  children: React.ReactNode;
}

const SHeaderCard = styled.div<IHeaderCardStyleProps>`
    margin: 0px;
    background: #FFFFFF;
    width: 100%;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0px 2px 8px 0px rgba(2, 26, 63, 0.04);
    font-family: PingFangSC-Regular, sans-serif;
  `;

const HeaderCard = (props: IHeaderCardProps) => {
  const { children, spanHeight } = props;
  return (
    <div style={{width: "100%", overflow:"hidden", paddingBottom: "10px"}}>
    <SHeaderCard {...props} spanHeight={spanHeight}>
      {children}
    </SHeaderCard>
    </div>
  );
};

HeaderCard.propTypes = {
  children: PropTypes.node.isRequired,
  spanHeight: PropTypes.bool
};

HeaderCard.defaultProps = {
  spanHeight: true
};

export default HeaderCard;
