import * as React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider, useRouteError } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import App from "./App";
import NotFoundPage from "./pages/404Page";
import BindWalletPage from "./pages/BindWalletPage";
import PaymentPage from "./pages/PaymentPage";
import PaymentResultPage from "./pages/PaymentResultPage";
import { globalStyle } from "./styles";

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

declare global {
  // tslint:disable-next-line
  interface Window {
    blockies: any;
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <>
      <GlobalStyle />
      <PaymentPage />
    </>,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/:txhash",
        element: <>
          <GlobalStyle />
          <PaymentResultPage />
        </>,
        errorElement: <NotFoundPage />
      }
    ]
  }, {
    path: "/bind",
    element: <>
      <GlobalStyle />
      <BindWalletPage />
    </>,
    errorElement: <NotFoundPage />
  }
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById("root"),
);
