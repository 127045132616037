import * as React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

interface IFooterStyleProps {
    maxWidth: number;
    center: boolean;
}

interface IFooterProps extends IFooterStyleProps {
    children: React.ReactNode;
}

const SFooter = styled.div<IFooterStyleProps>`
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  margin: 0 auto;
  display: flex;
  flex-direction: Footer;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
`;

const Footer = (props: IFooterProps) => {
    const { children, maxWidth, center } = props;
    return (
        <SFooter {...props} maxWidth={maxWidth} center={center}>
            {children}
        </SFooter>
    );
};

Footer.propTypes = {
    children: PropTypes.node.isRequired,
    spanHeight: PropTypes.bool,
    maxWidth: PropTypes.number,
    center: PropTypes.bool,
};

Footer.defaultProps = {
    spanHeight: false,
    maxWidth: 600,
    center: false,
};

export default Footer;
