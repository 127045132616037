import { ITransactionResult } from "./transactions"

const KEY_PREFIX = "transactions-"

export interface ITransactionStorage {
    transactionResult: ITransactionResult,
    sendTimes: number,
    others?: string
}

export const storeLocalTransaction = (_address: string, _transactionResult: ITransactionResult, _others?: string) => {
    const key = `${KEY_PREFIX}${_address}`
    const current: ITransactionStorage[] = listStoredTransactionByKey(key)
    current.push({
        transactionResult: _transactionResult,
        sendTimes: 0,
        others: _others
    })

    updateLocalTransaction(key, current)
}

export const listTransactionKeys = (): string[] => {
    const keys: string[] = new Array()
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key?.startsWith(KEY_PREFIX)) {
            keys.push(key)
        }
    }
    return keys
}

export const listStoredTransactionByAddress = (address: string): ITransactionStorage[] => {
    const key = `${KEY_PREFIX}${address.toLocaleLowerCase()}`
    return listStoredTransactionByKey(key)
}

export const listStoredTransactionByKey = (key: string): ITransactionStorage[] => {
    const stored = localStorage.getItem(key)
    if (!stored) {
        return []
    }
    return JSON.parse(stored)
}

export const getStoredTransactionByAddressAndHash = (address: string, txhash: string): ITransactionStorage | null => {
    const list = listStoredTransactionByAddress(address).filter((one) => one.transactionResult.txhash === txhash)
    if (list.length === 0) {
        return null
    }
    return list[0]
}

export const updateLocalTransaction = (key: string, storages: ITransactionStorage[]) => {
    localStorage.setItem(key, JSON.stringify(storages))
}

export const clearLocalTransaction = () => {
    localStorage.clear()
}