import { IChainData } from "../helpers/types";

// 所有的链清单
export const CHAINS: IChainData[] = [
  {
    name: "Ethereum Mainnet",
    short_name: "eth",
    chain: "ETH",
    network: "mainnet",
    chain_id: 1,
    network_id: 1,
    rpc_url: "https://mainnet.infura.io/v3/%API_KEY%",
    native_currency: {
      symbol: "ETH",
      name: "Ether",
      decimals: "18",
      contractAddress: undefined,
      abiFile: "",
      multiple: 1,
      balance: "",
    },
    supported_tokens: [{
      symbol: "USDT",
      name: "Tether USD",
      decimals: "6",
      contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      abiFile: "",
      multiple: 1000000,
      balance: ""
    }, {
      symbol: "USDC",
      name: "USD Coin",
      decimals: "6",
      contractAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      abiFile: "../abi/eth-usdc-erc20-abi.json",
      multiple: 1000000,
      balance: ""
    }, {
      symbol: "BUSD",
      name: "Binance USD",
      decimals: "18",
      contractAddress: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      abiFile: "../abi/eth-busd-erc20-abi.json",
      multiple: 1000000,
      balance: ""
    }]
  },
  {
    name: "Goerli Test Network",
    short_name: "gor",
    chain: "ETH",
    network: "goerli",
    chain_id: 5,
    network_id: 5,
    rpc_url: "https://goerli.infura.io/v3/%API_KEY%",
    native_currency: {
      symbol: "ETH",
      name: "Ether",
      decimals: "18",
      contractAddress: undefined,
      abiFile: "",
      multiple: 1,
      balance: "",
    },
    supported_tokens: [{
      symbol: "USDT",
      name: "USD Tether",
      decimals: "18",
      contractAddress: "0x2DB274b9E5946855B83e9Eac5aA6Dcf2c68a95F3",
      abiFile: "",
      multiple: 1000000,
      balance: ""
    }, {
      symbol: "USDC",
      name: "USD Coin",
      decimals: "6",
      contractAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
      abiFile: "../abi/eth-usdc-erc20-abi.json",
      multiple: 1000000,
      balance: ""
    }]
  },
  {
    name: "BNB Smart Chain Mainnet",
    short_name: "bsc",
    chain: "BSC",
    network: "mainnet",
    chain_id: 56,
    network_id: 56,
    rpc_url: "https://bsc-dataseed.binance.org/v3/%API_KEY%",
    native_currency: {
      symbol: "BNB",
      name: "BNB",
      decimals: "18",
      contractAddress: undefined,
      abiFile: "",
      multiple: 1,
      balance: "",
    },
    supported_tokens: [{
      symbol: "USDT",
      name: "Binance-Peg BSC-USD",
      decimals: "18",
      contractAddress: "0x55d398326f99059fF775485246999027B3197955",
      abiFile: "../abi/bsc-usdt-erc20-abi.json",
      multiple: 1,
      balance: ""
    }, {
      symbol: "USDC",
      name: "Binance-Peg USD Coin",
      decimals: "18",
      contractAddress: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      abiFile: "",
      multiple: 1,
      balance: ""
    }, {
      symbol: "BUSD",
      name: "Binance-Peg BUSD Token",
      decimals: "18",
      contractAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      abiFile: "",
      multiple: 1,
      balance: ""
    }]
  },
  {
    name: "Conflux eSpace",
    short_name: "conflux",
    chain: "CFX",
    network: "eSpace",
    chain_id: 1030,
    network_id: 1030,
    rpc_url: "https://evm.confluxrpc.com/%API_KEY%",
    native_currency: {
      symbol: "CFX",
      name: "CFX",
      decimals: "18",
      contractAddress: undefined,
      abiFile: "",
      multiple: 1,
      balance: "",
    },
    supported_tokens: [{
      symbol: "USDT",
      name: "Tether USD",
      decimals: "18",
      contractAddress: "0xfe97e85d13abd9c1c33384e796f10b73905637ce",
      abiFile: "",
      multiple: 1,
      balance: ""
    }]
  },
  {
    name: "Conflux eSpace (Testnet)",
    short_name: "conflux",
    chain: "CFX",
    network: "eSpace Testnet",
    chain_id: 71,
    network_id: 71,
    rpc_url: "https://evmtestnet.confluxrpc.com/%API_KEY%",
    native_currency: {
      symbol: "CFX",
      name: "CFX",
      decimals: "18",
      contractAddress: undefined,
      abiFile: "",
      multiple: 1,
      balance: "",
    },
    supported_tokens: [{
      symbol: "USDT",
      name: "Faucet USDT",
      decimals: "18",
      contractAddress: "0x7d682e65efc5c13bf4e394b8f376c48e6bae0355",
      abiFile: "",
      multiple: 1,
      balance: ""
    }]
  }
];
