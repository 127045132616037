// 钱包绑定界面

import * as React from "react";
import Column from "../components/Column";
import styled from "styled-components";
import {Button, Result} from "antd";
import WalletConnectDecorator from "../helpers/wallets";
import {LoadingOutlined} from '@ant-design/icons';
import { ethers } from "ethers";
import { ResultStatusType } from "antd/lib/result";
import HeaderCard from "src/components/HeaderCard";


const SLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  text-align: center;
`;

const CALLBACK_URL = "https://chain.nanjingyunpeng.cn/api/notify/walletlogs"

interface IBindWallectState {
    failMessage: string;
    state: "initial" | "connecting" | "connected" | "verifying" | "failed" | "success";
    address: string
}

function BindButton(props: any): JSX.Element {
    let buttonText = "bind";
    let icon = <></>;
    let disabled = false;
    
    if (props.state === "connecting" || props.state === "verifying") {
        buttonText = props.state;
        disabled = true;
        icon = <LoadingOutlined />;
    }

    switch (props.state) {
        case "connecting":
        case "verifying": {
            buttonText = props.state;
            break;
        }
        case "connected": {
            buttonText = "verify";
        }
    }

    if (props.state === "success") {
        return <></>;
    } else {
        return <Button
        icon={icon}
        disabled={disabled}
        onClick={props.onClick}
        type="primary"
        size="large"
        shape="round"
        style={{
          margin: "32px",
          width: "80%",
          minWidth: "300px",
          border: "0px",
          backgroundColor: (disabled) ? "#DDDDDD" : "#FF7143"
        }}
      >
        {buttonText}
      </Button>
    }
}

async function notifyResult(addr: string): Promise<boolean> {
    try {
        const url = CALLBACK_URL + window.location.search;
        console.log("bind callback url:", url);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({address: addr}),
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        });

        console.log('notify response:', response);

        return response.ok
    } catch (error) {
        console.error("notify bind result error:", error);
        return false;
    }
}

function BindTip(props: IBindWallectState): JSX.Element {
    let status: ResultStatusType = "info";
    let title = "";
    let subTitle = "";

    switch(props.state) {
        case "initial": {
            status = "info";
            title = "bind wallet to your account";
            subTitle = "click button below to start binding"
            break;
        }
        case "connecting": {
            status = "info";
            title = "connecting to your wallet";
            subTitle = "wait and accept connection request in your wallet app";
            break;
        }
        case "verifying": {
            status = "info";
            title = "verifying your wallet address";
            subTitle = "wait and accept sign message request in your wallet app";
            break;
        } 
        case "failed": {
            status = "error";
            title = "failed to bind your wallet";
            subTitle = props.failMessage;
            break;
        }
        case "connected": {
            status = "info";
            title = "wallet connected";
            subTitle = "click button below to sign with your wallet";
            break;
        }
        case "success": {
            status = "success";
            title = "bind wallet success";
            subTitle = "your wallet address is " + props.address;
            break;
        }
    }

    return (
        <Result status={status} title={title} subTitle={subTitle} />
    )
}

class BindWalletPage extends React.Component<any, IBindWallectState> {
    private wallectConnectDecorator = new WalletConnectDecorator()

    public constructor(props: any) {
        super(props);
        this.state = {
            state: "initial",
            failMessage: "",
            address: ""
        };
    }

    public verifyAddress = async (): Promise<boolean> => {
        if (this.wallectConnectDecorator.chainId !== 1) {
            await this.wallectConnectDecorator.switchChain(1);
        }
        
        const message = "sign this message to bind your wallet"

        const signature = await this.wallectConnectDecorator.web3Provider!.getSigner().signMessage(message);
        console.log("signature:", signature);
        const address = ethers.utils.verifyMessage(message, signature);
        console.log("address:", address);
        console.log("wallectconnect address:", this.wallectConnectDecorator.address);

        return address.toLowerCase() === this.wallectConnectDecorator.address.toLowerCase();
    }

    public handleVerify = async () => {
        try {
            const verifyResult = await this.verifyAddress();
            
            if (verifyResult) {
                const notifySuccess = await notifyResult(this.wallectConnectDecorator.address);

                if (notifySuccess) {
                    this.setState({
                        state: "success",
                        address: this.wallectConnectDecorator.address
                    });
                } else {
                    this.setState({
                        state: "failed",
                        failMessage: "server internal error"
                    })
                }                
            } else {
                this.setState({
                    state: "failed",
                    failMessage: "address mismatch"
                })
            }
        } catch (error) {
            this.setState({
                state: "failed",
                failMessage: error
            })
        }
    }

    public handleBindWallect = async () => {
        if (this.state.state === "connected") {
            await this.handleVerify();
            return;
        }

        this.setState({
            state: "connecting",
            failMessage: ""
        });

        try {
            await this.wallectConnectDecorator.connect();
        } catch(error) {
            console.log("connect to wallet failed:", error);
        }

        console.log("chainid =", this.wallectConnectDecorator.chainId);

        if (!this.wallectConnectDecorator.connected || ! this.wallectConnectDecorator.web3Provider) {
            console.log("connected failed");
            this.setState({
                state: "failed",
                failMessage: "failed to connect wallect"
            });
        } else {
            console.log("connected success");

            this.setState({
                state: "connected"
            })
        }
    }

    public render() : JSX.Element {
        return (
                <SLayout>
                    <Column maxWidth={500} spanHeight>
                        <HeaderCard>
                            <BindTip state={this.state.state} failMessage={this.state.failMessage} address={this.state.address} />
                        </HeaderCard>
                        <BindButton state={this.state.state} onClick={this.handleBindWallect} />
                    </Column>
                </SLayout>
        );
    }
}

export default BindWalletPage;